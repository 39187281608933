import * as types from "../types/navbar_type";

const updateSearchbarStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_NAVBAR_SEARCHBAR_STATUS,
    status: status,
  });
};

const updateFilterStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_NAVBAR_FILTER_STATUS,
    status: status,
  });
};

const updateSearchBarClickedScrollY = (scrollY) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_SEARCH_BAR_CLICKED_SCROLL_Y,
    scrollY: scrollY,
  });
};

const updateShowSignInModalStatus = (status) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_SHOW_SIGNIN_MODAL_STATUS,
        status: status,
      })
    );
  });
};

const updateSignInModalSignUpStatus = (status) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_SIGNIN_MODAL_SIGNUP_STATUS,
        status: status,
      })
    );
  });
};

const updateSignInText = (text) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_SIGNIN_TEXT,
        text: text,
      })
    );
  });
};

const updateSignUpText = (text) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_SIGNUP_TEXT,
        text: text,
      })
    );
  });
};

const updateShowMobileFilter = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_SHOW_MOBILE_FILTER_STATUS,
    status: status,
  });
};

const updateShowMobileSearch = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_SHOW_MOBILE_SEARCH,
    status: status,
  });
};

const updateNavbarSearchKeyword = (keyword) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_NAVBAR_SEARCH_KEYWORD,
    keyword: keyword,
  });
};

const updateShowStickyApply = (flag) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_SHOW_STICKY_APPLY_BUTTON,
    toggle: flag,
  });
};

export {
  updateFilterStatus,
  updateNavbarSearchKeyword,
  updateSearchBarClickedScrollY,
  updateSearchbarStatus,
  updateShowMobileFilter,
  updateShowMobileSearch,
  updateShowSignInModalStatus,
  updateShowStickyApply,
  updateSignInModalSignUpStatus,
  updateSignInText,
  updateSignUpText,
};
