// DIALOG MANAGEMENT

export const SHOW_INFO_DIALOG = "SHOW_INFO_DIALOG";
export const HIDE_INFO_DIALOG = "HIDE_INFO_DIALOG";
export const CLEAR_INFO_CONTENT = "CLEAR_INFO_CONTENT";

export const SHOW_CONFIRMATION_DIALOG = "SHOW_CONFIRMATION_DIALOG";
export const HIDE_CONFIRMATION_DIALOG = "HIDE_CONFIRMATION_DIALOG";
export const CLEAR_CONFIRMATION_CONTENT = "CLEAR_CONFIRMATION_CONTENT";

export const UPDATE_NO_CV_DIALOG_STATUS = "UPDATE_NO_CV_DIALOG_STATUS";

export const UPDATE_JOB_APPLIED_DIALOG = "UPDATE_JOB_APPLIED_DIALOG";

export const UPDATE_JOB_ALERT_DIALOG = "UPDATE_JOB_ALERT_DIALOG";

export const UPDATE_JOB_ALERT_NOTIFICATIONS_DIALOG =
  "UPDATE_JOB_ALERT_NOTIFICATIONS_DIALOG";

export const UPDATE_JOB_ALERT_DELETE_DIALOG = "UPDATE_JOB_ALERT_DELETE_DIALOG";

export const UPDATE_JOB_ALERT_DOWNLOAD_APP_DIALOG =
  "UPDATE_JOB_ALERT_DOWNLOAD_APP_DIALOG";

export const UPDATE_JOB_ALERT_VISITOR_EMAIL_DIALOG =
  "UPDATE_JOB_ALERT_VISITOR_EMAIL_DIALOG";

export const UPDATE_JOB_ALERT_VISITOR_UNSUBSCRIBE_DIALOG =
  "UPDATE_JOB_ALERT_VISITOR_UNSUBSCRIBE_DIALOG";

export const SHOW_INFO_DIALOG_ACTIVELY_AVAILABLE =
  "SHOW_INFO_DIALOG_ACTIVELY_AVAILABLE";

export const UPDATE_JOB_ALERT_CREATE_DIALOG = "UPDATE_JOB_ALERT_CREATE_DIALOG";

export const UPDATE_JOB_ALERT_CREATE_DIALOG_KEYWORD =
  "UPDATE_JOB_ALERT_CREATE_DIALOG_KEYWORD";

export const UPDATE_JOB_DETAILS_VISITOR_JOB_ALERT_DIALOG =
  "UPDATE_JOB_DETAILS_VISITOR_JOB_ALERT_DIALOG";

export const UPDATE_VISITOR_JOB_ALERT_DIALOG_DETAILS =
  "UPDATE_VISITOR_JOB_ALERT_DIALOG_DETAILS";

export const UPDATE_VISITOR_JOB_ALERT_DIALOG_ORIGIN =
  "UPDATE_VISITOR_JOB_ALERT_DIALOG_ORIGIN";

export const UPDATE_SECONDARY_VISITOR_DIALOG =
  "UPDATE_SECONDARY_VISITOR_DIALOG";

export const UPDATE_RESUME_CONFIRMATION_DIALOG =
  "UPDATE_RESUME_CONFIRMATION_DIALOG";
export const UPDATE_PROFILE_PREVIEW_DIALOG = "UPDATE_PROFILE_PREVIEW_DIALOG";
export const UPDATE_SIGN_UP_PROFILE_DIALOG = "UPDATE_SIGN_UP_PROFILE_DIALOG";

export const UPDATE_ASHLEY_EDIT_NO_RESUME_DIALOG =
  "UPDATE_ASHLEY_EDIT_NO_RESUME_DIALOG";
export const UPDATE_ASHLEY_EDIT_DIALOG = "UPDATE_ASHLEY_EDIT_DIALOG";
export const UPDATE_ASHLEY_EDIT_CONFIRMATION_DIALOG =
  "UPDATE_ASHLEY_EDIT_CONFIRMATION_DIALOG";

export const UPDATE_CONTACT_US_EMPLOYER_DIALOG =
  "UPDATE_CONTACT_US_EMPLOYER_DIALOG";

export const UPDATE_SG_JOB_REMINDER_DIALOG = "UPDATE_SG_JOB_REMINDER_DIALOG";
