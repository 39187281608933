export default function escapeDoubleQuoteFromPayload(payload) {
  const doubleQuotePattern = /"/g;
  const escapedDoubleQuote = '\\"';

  // For null property
  if (!payload) return payload;

  if (typeof payload === "string") {
    return payload.replace(doubleQuotePattern, escapedDoubleQuote);
  } else if (Array.isArray(payload) && payload.length) {
    return payload?.map((value) => {
      return escapeDoubleQuoteFromPayload(value);
    });
  } else if (typeof payload === "object" && Object.keys(payload)?.length) {
    let formmatedPayloadObj = {};
    for (const key in payload) {
      formmatedPayloadObj[key] = escapeDoubleQuoteFromPayload(payload[key]);
    }
    return formmatedPayloadObj;
  } else {
    return payload;
  }
}
