//FETCHING COMPANY LIST
export const FETCHING_COMPANY_KEY = "FETCHING_COMPANY_KEY";
export const FETCHING_COMPANIES = "FETCHING_COMPANIES";
export const FETCH_COMPANIES_SUCCEED = "FETCH_COMPANIES_SUCCEED";
export const FETCH_COMPANIES_FAILED = "FETCH_COMPANIES_FAILED";
export const CLEAR_COMPANY_LIST = "CLEAR_COMPANY_LIST";

//FETCHING MICROSITE COMPANY LIST
export const FETCH_MICROSITE_COMPANIES_SUCCEED =
  "FETCH_MICROSITE_COMPANIES_SUCCEED";
export const FETCH_MICROSITE_COMPANIES_FAILED =
  "FETCH_MICROSITE_COMPANIES_FAILED";

// FOR COMPANY SUGGESTION
export const FETCH_COMPANY_SUGGESTION_KEY = "FETCH_COMPANY_SUGGESTION_KEY";
export const FETCH_COMPANY_SUGGESTION = "FETCH_COMPANY_SUGGESTION";
export const FETCH_COMPANY_SUGGESTION_SUCCEED =
  "FETCH_COMPANY_SUGGESTION_SUCCEED";
export const FETCH_COMPANY_SUGGESTION_FAILED =
  "FETCH_COMPANY_SUGGESTION_FAILED";
export const CLEAR_COMPANY_SUGGESTION = "CLEAR_COMPANY_SUGGESTION";

//FETCHING COMPANY SUGGESTION
export const FETCHING_COMPANY_SUGGESTION = "FETCHING_COMPANY_SUGGESTION";
export const FETCHING_COMPANY_SUGGESTION_SUCCEED =
  "FETCHING_COMPANY_SUGGESTION_SUCCEED";
export const FETCHING_COMPANY_SUGGESTION_FAILED =
  "FETCHING_COMPANY_SUGGESTION_FAILED";

//COMPANY FILTER
export const UPDATE_COMPANY_FILTER = "UPDATE_COMPANY_FILTER";

//FETCHING DISCOVER MORE COMPANY LIST
export const FETCH_DISCOVER_MORE_COMPANIES = "FETCH_DISCOVER_MORE_COMPANIES";
export const FETCH_DISCOVER_MORE_COMPANIES_SUCCEED =
  "FETCH_DISCOVER_MORE_COMPANIES_SUCCEED";
export const FETCH_DISCOVER_MORE_COMPANIES_FAILED =
  "FETCH_DISCOVER_MORE_COMPANIES_FAILED";
export const CLEAR_DISCOVER_MORE_COMPANY_LIST =
  "CLEAR_DISCOVER_MORE_COMPANY_LIST";
export const NO_DISCOVER_MORE_COMPANIES_FOUND =
  "NO_DISCOVER_MORE_COMPANIES_FOUND";

//COMPANY PROFILE PAGE
export const UPDATE_SELECTED_COMPANY = "UPDATE_SELECTED_COMPANY";
export const FETCHING_COMPANY = "FETCHING_COMPANY";
export const FETCHING_COMPANY_SUCCEED = "FETCHING_COMPANY_SUCCEED";
export const FETCHING_COMPANY_FAILED = "FETCHING_COMPANY_FAILED";

//FETCH COMPANY BOOKMARK
export const FETCHING_COMPANY_BOOKMARK = "FETCHING_COMPANY_BOOKMARK";
export const FETCHING_COMPANY_BOOKMARK_SUCCEED =
  "FETCHING_COMPANY_BOOKMARK_SUCCEED";
export const FETCHING_COMPANY_BOOKMARK_FAILED =
  "FETCHING_COMPANY_BOOKMARK_FAILED";

// FETCH COMPANY AFFILIATE
export const FETCH_AFFILIATE_COMPANY = "FETCH_AFFILIATE_COMPANY";
export const FETCH_AFFILIATE_COMPANY_SUCCEED =
  "FETCH_AFFILIATE_COMPANY_SUCCEED";
export const FETCH_AFFILIATE_COMPANY_FAILED = "FETCH_AFFILIATE_COMPANY_FAILED";

//  FETCH DISCOVER COMPANY
export const FETCH_DISCOVER_COMPANIES = "FETCH_DISCOVER_COMPANIES";
export const FETCH_DISCOVER_COMPANIES_SUCCEED =
  "FETCH_DISCOVER_COMPANIES_SUCCEED";
export const FETCH_DISCOVER_COMPANIES_FAILED =
  "FETCH_DISCOVER_COMPANIES_FAILED";

// FETCHING COMPANY JOBS
export const FETCH_COMPANY_JOBS = "FETCH_COMPANY_JOBS";
export const FETCH_COMPANY_JOBS_SUCCEED = "FETCH_COMPANY_JOBS_SUCCEED";
export const FETCH_COMPANY_JOBS_FAILED = "FETCH_COMPANY_JOBS_FAILED";

// BOOKMARK COMPANY
export const BOOKMARK_COMPANY = "BOOKMARK_COMPANY";
export const BOOKMARK_COMPANY_SUCCEED = "BOOKMARK_COMPANY_SUCCEED";
export const BOOKMARK_COMPANY_FAILED = "BOOKMARK_COMPANY_FAILED";

// BOOKMARK AFFILIATE COMPANY
export const BOOKMARK_AFFILIATE_COMPANY = "BOOKMARK_AFFILIATE_COMPANY";
export const BOOKMARK_AFFILIATE_COMPANY_SUCCEED =
  "BOOKMARK_AFFILIATE_COMPANY_SUCCEED";
export const BOOKMARK_AFFILIATE_COMPANY_FAILED =
  "BOOKMARK_AFFILIATE_COMPANY_FAILED";

// BOOKMARK DISCOVER COMPANY
export const BOOKMARK_DISCOVER_COMPANY = "BOOKMARK_DISCOVER_COMPANY";
export const BOOKMARK_DISCOVER_COMPANY_SUCCEED =
  "BOOKMARK_DISCOVER_COMPANY_SUCCEED";
export const BOOKMARK_DISCOVER_COMPANY_FAILED =
  "BOOKMARK_DISCOVER_COMPANY_FAILED";

// BOOKMARK COMPANY JOB
export const BOOKMARK_COMPANY_JOB = "BOOKMARK_COMPANY_JOB";
export const BOOKMARK_COMPANY_JOB_SUCCEED = "BOOKMARK_COMPANY_JOB_SUCCEED";
export const BOOKMARK_COMPANY_JOB_FAILED = "BOOKMARK_COMPANY_JOB_FAILED";

// UNBOOKMARK COMPANY
export const UNBOOKMARK_COMPANY = "UNBOOKMARK_COMPANY";
export const UNBOOKMARK_COMPANY_SUCCEED = "UNBOOKMARK_COMPANY_SUCCEED";
export const UNBOOKMARK_COMPANY_FAILED = "UNBOOKMARK_COMPANY_FAILED";

// UNBOOKMARK DISCOVER COMPANY
export const UNBOOKMARK_DISCOVER_COMPANY = "UNBOOKMARK_DISCOVER_COMPANY";
export const UNBOOKMARK_DISCOVER_COMPANY_SUCCEED =
  "UNBOOKMARK_DISCOVER_COMPANY_SUCCEED";
export const UNBOOKMARK_DISCOVER_COMPANY_FAILED =
  "UNBOOKMARK_DISCOVER_COMPANY_FAILED";

// UNBOOKMARK AFFILIATE COMPANY
export const UNBOOKMARK_AFFILIATE_COMPANY = "UNBOOKMARK_AFFILIATE_COMPANY";
export const UNBOOKMARK_AFFILIATE_COMPANY_SUCCEED =
  "UNBOOKMARK_AFFILIATE_COMPANY_SUCCEED";
export const UNBOOKMARK_AFFILIATE_COMPANY_FAILED =
  "UNBOOKMARK_AFFILIATE_COMPANY_FAILED";

// UNBOOKMARK COMPANY JOB
export const UNBOOKMARK_COMPANY_JOB = "UNBOOKMARK_COMPANY_JOB";
export const UNBOOKMARK_COMPANY_JOB_SUCCEED = "UNBOOKMARK_COMPANY_JOB_SUCCEED";
export const UNBOOKMARK_COMPANY_JOB_FAILED = "UNBOOKMARK_COMPANY_JOB_FAILED";

//GET INDUSTRY
export const FETCHING_INDUSTRY_SUCCEED = "FETCHING_INDUSTRY_SUCCEED";
export const FETCHING_INDUSTRY_FAILED = "FETCHING_INDUSTRY_FAILED";

//GET STATE REGION
export const FETCHING_STATE_REGION_SUCCEED = "FETCHING_STATE_REGION_SUCCEED";
export const FETCHING_STATE_REGION_FAILED = "FETCHING_STATE_REGION_FAILED";
export const UPDATE_STATE_REGION = "UPDATE_STATE_REGION";

//GET COMPANY SIZE
export const FETCHING_COMPANY_SIZE_SUCCEED = "FETCHING_COMPANY_SIZE_SUCCEED";
export const FETCHING_COMPANY_SIZE_FAILED = "FETCHING_COMPANY_SIZE_FAILED";

// FETCHING BOOKMARK COMPANY LIST
export const FETCH_COMPANY_BOOKMARK_LIST = "FETCH_COMPANY_BOOKMARK_LIST";
export const FETCH_COMPANY_BOOKMARK_LIST_SUCCEED =
  "FETCH_COMPANY_BOOKMARK_LIST_SUCCEED";
export const FETCH_COMPANY_BOOKMARK_LIST_FAILED =
  "FETCH_COMPANY_BOOKMARK_LIST_FAILED";
export const CLEAR_COMPANY_BOOKMARK_LIST = "CLEAR_COMPANY_BOOKMARK_LIST";

// FETCHING STATIC COMPANY LIST
export const FETCH_STATIC_COMPANIES_LIST = "FETCH_STATIC_COMPANIES_LIST";
export const FETCH_STATIC_COMPANIES_LIST_SUCCEED =
  "FETCH_STATIC_COMPANIES_LIST_SUCCEED";
export const FETCH_STATIC_COMPANIES_LIST_FAILED =
  "FETCH_STATIC_COMPANIES_LIST_FAILED";

// FETCHING HOMEPAGE HIRING COMPANIES
export const FETCHING_HOMEPAGE_HIRING_COMPANIES =
  "FETCHING_HOMEPAGE_HIRING_COMPANIES";
export const FETCH_HOMEPAGE_HIRING_COMPANIES_SUCCEED =
  "FETCH_HOMEPAGE_HIRING_COMPANIES_SUCCEED";
export const FETCH_HOMEPAGE_HIRING_COMPANIES_FAILED =
  "FETCH_HOMEPAGE_HIRING_COMPANIES_FAILED";

export const FETCH_COMPANY_BANNER_SUCCEED = "FETCH_COMPANY_BANNER_SUCCEED";
export const FETCH_COMPANY_BANNER_FAILED = "FETCH_COMPANY_BANNER_FAILED";

export const UPDATE_COMPANY_SEARCH_KEYWORD = "UPDATE_COMPANY_SEARCH_KEYWORD";

export const FETCH_EMPLOYER_TRUSTED_BRAND = "FETCH_EMPLOYER_TRUSTED_BRAND";
export const FETCH_EMPLOYER_TRUSTED_BRAND_FAILED =
  "FETCH_EMPLOYER_TRUSTED_BRAND_FAILED";

export const FETCH_EMPLOYER_TESTIMONIALS = "FETCH_EMPLOYER_TESTIMONIALS";
export const FETCH_EMPLOYER_TESTIMONIALS_FAILED =
  "FETCH_EMPLOYER_TESTIMONIALS_FAILED";

export const FETCH_EMPLOYER_EXTERNAL_JOB_BOARD_SUCCEED =
  "FETCH_EMPLOYER_EXTERNAL_JOB_BOARD_SUCCEED";
