import { isAiviSession } from "../../helpers/data_management";
import * as types from "../types/user_type";

const initialState = {
  // For login
  loggingIn: false,
  user: {},
  basicInfo: {},
  error: false,
  // For social login
  socialLoggingIn: false,
  socialLoggingInError: false,
  // For registration
  registering: false,
  // For user profile
  drawerRowIndex: -1,
  currentUser: {},
  // For onboarding
  updateOnboardingInfo: false,
  onboarding: false,
  showOnboardingCompletionDialog: false,
  birthDateValid: {},
  fullNameValid: {},
  onboardingJobTitles: [],
  onboardingFields: {
    // For new onboarding
    name: "",
    jobTitle: "",
    industry: "",
    specialisation: "",
    industryId: -1,
    major: "",
    skills: [],
    currentSkill: "",
    nationality: "",
    mobileNumber: "",
    currentLocation: "",
    // End for new onboarding
    fullName: "",
    gender: "",
    birthdate: "",
    highestEducationLevel: "",
    fieldOfStudy: "",
    customFieldOfStudy: "",
    graduationYear: "",
    educationalInstitution: "",
    customEducationalInstitution: "",
    currentCompany: "",
    currentPosition: "",
    interestedJobTitle: "",
    profileImage: "",
    cvPath: "",
    cvFileName: "",
    freshGrad: null,
  },
  onboardingStatus: {
    // Name field states
    doneNaming: false,
    // showNameEnterText: false,
    // showEnteredName: false,
    // isDescriptionShow: false,
    // isTextboxShow: false,
    nameError: false,

    // Mobile number field states
    doneMobileNumber: false,
    mobileNumberError: false,

    // Nationality field states
    doneNationality: false,

    // Current location field states
    doneCurrentLocation: false,

    proceedToSkillPage: false,
    proceedToSkillPageFromExperience: false,
    proceedToRecommendedJobPage: false,
    hideUnemployedText: false,
    doneJobTitle: false,
    doneIndustry: false,
    doneSpecialisation: false,
    jobTitleError: false,
    industryError: false,
    specialisationError: false,
    doneMajor: false,
    majorError: false,
    doneCurrentSkill: false,
    doneNextSkill: true,
    skillError: false,
  },
  // For updating user profile
  updatingUserProfile: false,
  updatingUserProfileError: false,
  profileFields: {
    // Old
    email: "",
    fullName: "",
    nationality: "",
    gender: "",
    birthdate: "",
    mobileNumber: "",
    highestEducationLevel: "",
    fieldOfStudy: "",
    customFieldOfStudy: "",
    graduationYear: "",
    educationalInstitution: "",
    customEducationalInstitution: "",
    currentCompany: "",
    currentPosition: "",
    interestedJobTitle: "",
    shortSummary: "",
    profileImage: "",
    cvPath: "",
    cvFileName: "",
    freshGrad: false,
    activelyAvailable: false,
    educationDesc: "",
    languages: [],
    skills: [],

    lightCastSkills: [],
    lightCastCertificates: [],
    // For certificates
    certificates: [],
    certificatesForms: {
      title: "",
      issuing_organization: "",
      completion_year: 0,
      credential_url: "",
    },
    // For honours
    honoursForms: {
      title: "",
      description: "",
      completion_year: 0,
    },
    // For extracurricular
    extracurricularForms: {
      title: "",
      description: "",
      completion_year: 0,
    },
    // For work
    work: [],
    workForms: {
      companyName: "",
      jobTitle: "",
      description: "",
      specialization: "",
      industryId: 0,
      startDateMonth: 0,
      startDateYear: 0,
      endDateMonth: 0,
      endDateYear: 0,
      currentWork: true,
    },
    // For saving user skill
    savingSkills: false,
    savingSkillsError: "",
    userSkillInput: "",
    // For education section
    educations: {},
    updatingEducations: false,
    updatingEducationsErr: "",
    educationForm: {
      educationId: "",
      educationLevel: "",
      educationalInstitution: "",
      fieldOfStudy: "",
      graduationYear: "",
      description: "",
      destroyEducation: false,
    },
    workingExps: { total_count: 0 },
    honorAndAwards: {},
    extracurricular: {},
    projects: {},
    certAndTrainings: {},
    savingUserLanguages: false,
    projectForm: {
      projectId: "",
      projectTitle: "",
      projectYear: "",
      projectUrl: "",
      description: "",
      destroyProject: false,
    },
    savingUserProjects: false,
    loadState: false,
  },
  // For getting user info
  fetchingUserInfo: false,
  fetchingUserInfoError: false,
  // For updating user profile image,
  updatingUserProfileImage: false,
  updatingUserProfileImageError: false,
  // For updating user resume
  updatingUserCv: false,
  updatingUserCvError: false,
  // For talent available
  updatingTalentAvailable: false,
  updatingTalentAvailableError: false,
  // For VCL upload
  fetchingPresignedUrl: false,
  fetchingPresignedUrlError: false,
  updatingVclPublicUrl: false,
  updatingVclPublicUrlError: false,

  fetchingOpenChat: false,
  fetchingOpenChatError: false,
  fetchingClosedChat: false,
  fetchingChatMessages: false,
  fetchingChatMessagesError: false,
  sendingMessage: false,
  sendingMessageError: false,
  unreadMessageCount: 0, // For open chat only
  openChats: [],
  closedChats: [],
  messages: [],
  currentChat: {}, // Current channel
  isAiviSessions: {},
  // For changing user password
  changingUserPassword: false,
  changingUserPasswordError: false,
  // Forgetting password
  forgettingPassword: false,
  forgettingPasswordError: false,
  // For password reset
  resettingPassword: false,
  resettingPasswordError: false,
  // For contact us
  submittingContactUs: false,
  submittingContactUsError: false,
  // For certificate section
  submitCertificateError: false,
  // For honours section
  submitHonourError: false,
  // For extracurricular section
  submitExtracurricularError: false,
  // For work section
  submitWorkError: false,
  // For resume replacement popup
  isReplaceResumeOpen: false,
  // For creating/uploading/done resume progress popup
  isResumeProgressOpen: false,
  isBuildingProgress: false,
  isUploadingProgress: false,
  // For resume creation popup
  isCreateResumeOpen: false,
  // For rejected resume popup
  isRejectedResumeOpen: false,
  isHiredlyResumeRejected: null,
  // For in review resume popup
  isInReviewResumeOpen: false,
  // For preview resume popup
  isPreviewResumeOpen: false,
  isPreviewResume: false,
  // For failed upload resume popup
  isFailedUploadResumeOpen: false,
  // For linkedin resume popup
  isLinkedinResumeOpen: false,
  //For validating hiredly resume content
  validatingHiredlyResume: false,

  // For tooltip
  currentTooltip: "",
  tooltipOpen: null,
  isRemovingProfilePhoto: false,
  // For new onboarding
  currentOnboardingPage: "welcome",
  jobTitleDropdownStatus: false,
  industryDropdownStatus: false,
  specialisationDropdownStatus: false,
  majorDropdownStatus: false,
  skillDropdownStatus: false,
  progressbarValue: 0,
  selectedRecommendedJobs: [],
  isBulkJobsPopupOpen: false,
  sliderDotsHistory: [],
  isLanguageCheckFail: false,
  // For nationality & mobile number dialog
  showNationalityDialog: false,
  // For secondary visitor
  resume: "",
  // Resume popup for ashley profile short summary
  fetchingAshleyShortSummary: false,
  ashleyShortSummaryResponse: [],
  selectedAshleyShortSummary: "",
  // For opening specialisation dropdown
  openSpecialisationDropdown: false,
  openSubSpecialisationDropdown: false,
  subSpecialisation: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_SELECTED_SHORT_SUMMARY: {
      return {
        ...state,
        selectedAshleyShortSummary: action.payload,
      };
    }
    case types.FETCH_ASHLEY_SHORT_SUMMARY_SUCCEED: {
      return {
        ...state,
        ashleyShortSummaryResponse: action.payload,
        fetchingAshleyShortSummary: false,
      };
    }
    case types.FETCH_ASHLEY_SHORT_SUMMARY_FAILED: {
      return {
        ...state,
      };
    }
    case types.FETCHING_ASHLEY_SHORT_SUMMARY: {
      return {
        ...state,
        fetchingAshleyShortSummary: true,
      };
    }
    // For nationality & mobile number fields
    case types.UPDATE_NATIONALITY_AND_MOBILE_NUMBER_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          mobile_number: action.payload.mobile_number,
          nationality: action.payload.nationality,
        },
      };
    }
    // For nationality & mobile number dialog
    case types.UPDATE_NATIONALITY_AND_MOBILE_NUMBER_DIALOG: {
      return {
        ...state,
        showNationalityDialog: action.payload.showNationalityDialog,
      };
    }
    // For user profile
    case types.UPDATE_DRAWER_INDEX: {
      return {
        ...state,
        drawerRowIndex: action.payload,
      };
    }
    case types.UPDATE_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    // For registration
    case types.REGISTERING_ACCOUNT: {
      return {
        ...state,
        registering: true,
      };
    }
    case types.REGISTER_ACCOUNT_SUCCEED: {
      return {
        ...state,
        user: action.user,
        registering: false,
        error: false,
      };
    }
    case types.REGISTER_ACCOUNT_FAILED: {
      return {
        ...state,
        registering: false,
        error: true,
      };
    }
    // For onboarding
    case types.UPDATE_ONBOARDING_INFO: {
      return {
        ...state,
        updateOnboardingInfo: true,
      };
    }

    case types.UPDATE_ONBOARDING_INFO_SUCCEED: {
      return {
        ...state,
        updateOnboardingInfo: false,
        user: action.user,
      };
    }

    case types.UPDATE_ONBOARDING_INFO_FAILED: {
      return {
        ...state,
        updateOnboardingInfo: false,
      };
    }

    case types.UPDATE_ONBOARDING_DIALOG_STATUS: {
      return {
        ...state,
        showOnboardingCompletionDialog: action.status,
      };
    }

    case types.VALIDATE_FULLNAME_SUCCEED: {
      return {
        ...state,
        fullNameValid: true,
      };
    }
    case types.VALIDATE_FULLNAME_FAILED: {
      return {
        ...state,
        fullNameValid: false,
      };
    }
    case types.UPDATE_ONBOARDING_FIELDS: {
      return {
        ...state,
        onboardingFields: { ...action.payload },
      };
    }

    case types.UPDATE_ONBOARDING_STATUS: {
      return {
        ...state,
        onboardingStatus: { ...action.onboardingStatus },
      };
    }

    case types.RESET_ONBOARDING_FIELDS: {
      return {
        ...state,
        onboardingFields: {
          ...initialState.onboardingFields,
        },
      };
    }
    case types.RESET_ONBOARDING_STATUS: {
      return {
        ...state,
        onboardingStatus: {
          ...initialState.onboardingStatus,
        },
      };
    }
    case types.UPDATE_LIGHTCAST_JOBS_LIST: {
      return {
        ...state,
        onboardingJobTitles: action.payload.obtainedJobs,
      };
    }
    //For login
    case types.LOGGING_USER_IN: {
      return {
        ...state,
        loggingIn: true,
      };
    }
    case types.REDIRECT_NEW_EMAIL_SIGNUP: {
      return {
        ...state,
        user: action.payload.userEmail,
      };
    }
    case types.SESSION_LOGIN_FAILED: {
      return {
        ...state,
        loggingIn: false,
        error: true,
      };
    }
    case types.SESSION_LOGIN_FAILED_INVALID_EMAIL: {
      return {
        ...state,
        loggingIn: false,
        error: true,
      };
    }
    // For social login
    case types.SOCIAL_LOGIN: {
      return {
        ...state,
        socialLoggingIn: true,
        socialLoggingInError: false,
      };
    }
    case types.SOCIAL_LOGIN_SUCCEED: {
      return {
        ...state,
        user: action.payload.user,
        socialLoggingIn: false,
        socialLoggingInError: false,
      };
    }
    case types.SOCIAL_LOGIN_FAILED: {
      return {
        ...state,
        socialLoggingIn: false,
        socialLoggingInError: true,
      };
    }
    // For update user info
    case types.UPDATE_USER: {
      return {
        ...state,
        user: action.user,
      };
    }
    // For logout
    case types.LOGOUT: {
      return initialState;
    }
    // For updating user profile
    case types.UPDATE_PROFILE_FIELDS: {
      return {
        ...state,
        profileFields: { ...action.payload },
      };
    }
    case types.CLEAR_PROFILE_FIELDS: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
        },
      };
    }
    case types.UPDATING_USER_PROFILE: {
      return {
        ...state,
        updatingUserProfile: true,
      };
    }
    case types.UPDATE_USER_PROFILE_SUCCEED: {
      return {
        ...state,
        user: action.user,
        updatingUserProfile: false,
        updatingUserProfileError: false,
      };
    }
    case types.UPDATE_JOB_PREF_SKILLS_SUCCEED: {
      return {
        ...state,
        user: {
          ...state.user,
          skills: action.payload.newJobPrefSkills,
        },
        updatingUserProfile: false,
        updatingUserProfileError: false,
      };
    }
    case types.UPDATE_USER_PROFILE_FAILED: {
      return {
        ...state,
        updatingUserProfile: false,
        updatingUserProfileError: true,
      };
    }
    // For getting user info
    case types.FETCHING_USER_INFO: {
      return {
        ...state,
        fetchingUserInfo: true,
      };
    }
    case types.FETCH_USER_INFO_SUCCEED: {
      return {
        ...state,
        user: action.user,
        fetchingUserInfo: false,
        fetchingUserInfoError: false,
      };
    }
    case types.FETCH_USER_INFO_FAILED: {
      return {
        ...state,
        fetchingUserInfo: false,
        fetchingUserInfoError: true,
      };
    }
    // For updating user profile image
    case types.UPDATING_USER_PROFILE_IMAGE: {
      return {
        ...state,
        updatingUserProfileImage: true,
      };
    }
    case types.UPDATE_USER_PROFILE_IMAGE_SUCCEED: {
      return {
        ...state,
        user: action.user,
        updatingUserProfileImage: false,
        updatingUserProfileImageError: false,
      };
    }
    case types.UPDATE_USER_PROFILE_IMAGE_FAILED: {
      return {
        ...state,
        updatingUserProfileImage: false,
        updatingUserProfileImageError: true,
      };
    }
    // For updating user resume
    case types.UPDATING_USER_CV: {
      return {
        ...state,
        updatingUserCv: true,
      };
    }
    case types.UPDATE_USER_CV_SUCCEED: {
      return {
        ...state,
        user: action.user,
        updatingUserCv: false,
        updatingUserCvError: false,
      };
    }
    case types.UPDATE_USER_CV_FAILED: {
      return {
        ...state,
        updatingUserCv: false,
        updatingUserCvError: true,
      };
    }

    case types.UPDATE_RESUME_FAIL_POPUP_STATUS: {
      return {
        ...state,
        updatingUserCvError: false,
      };
    }

    // For talent available
    case types.UPDATING_TALENT_AVAILABLE: {
      return {
        ...state,
        updatingTalentAvailable: true,
      };
    }
    case types.UPDATE_TALENT_AVAILABLE_SUCCEED: {
      return {
        ...state,
        updatingTalentAvailable: false,
        user: action.user,
      };
    }
    case types.UPDATE_TALENT_AVAILABLE_FAILED: {
      return {
        ...state,
        updatingTalentAvailable: false,
        updatingTalentAvailableError: true,
      };
    }
    // For VCL upload
    case types.FETCHING_PRESIGNED_URL: {
      return {
        ...state,
        fetchingPresignedUrl: true,
        fetchingPresignedUrlError: false,
      };
    }
    case types.FETCH_PRESIGNED_URL_SUCCEED: {
      return {
        ...state,
        fetchingPresignedUrl: false,
      };
    }
    case types.FETCH_PRESIGNED_URL_FAILED: {
      return {
        ...state,
        fetchingPresignedUrl: false,
        fetchingPresignedUrlError: true,
      };
    }
    case types.UPDATING_VCL_PUBLIC_URL: {
      return {
        ...state,
        updatingVclPublicUrl: true,
        updatingVclPublicUrlError: false,
      };
    }
    case types.UPDATE_VCL_PUBLIC_URL_SUCCEED: {
      return {
        ...state,
        updatingVclPublicUrl: false,
      };
    }
    case types.UPDATE_VCL_PUBLIC_URL_FAILED: {
      return {
        ...state,
        updatingVclPublicUrl: false,
        updatingVclPublicUrlError: true,
      };
    }
    // For chat
    // Open list
    case types.FETCHING_CHAT_LIST: {
      return {
        ...state,
        fetchingOpenChat: true,
        fetchingOpenChatError: false,
        isAiviSessions: {},
      };
    }
    case types.FETCH_CHAT_LIST_SUCCEED: {
      // Update channel aivi status based on members array
      let openChats = [...action.chatList];
      let isAiviSessions = {};

      openChats.map((chat) => {
        let isAivi = isAiviSession(chat);
        isAiviSessions[chat.url] = isAivi;
      });

      var msgCount = 0;
      openChats.forEach((channel) => {
        msgCount += channel.unreadMessageCount;
      });

      return {
        ...state,
        unreadMessageCount: msgCount,
        openChats: openChats,
        isAiviSessions: isAiviSessions,
        fetchingOpenChat: false,
      };
    }
    case types.FETCH_CHAT_LIST_FAILED: {
      return {
        ...state,
        fetchingOpenChat: false,
        fetchingOpenChatError: true,
      };
    }
    // Closed list
    case types.FETCHING_CLOSED_CHAT_LIST: {
      return {
        ...state,
        fetchingClosedChat: true,
        fetchingClosedChatError: false,
      };
    }
    case types.FETCH_CLOSED_CHAT_LIST_SUCCEED: {
      return {
        ...state,
        closedChats: action.chatList,
        fetchingClosedChat: false,
      };
    }
    case types.FETCH_CLOSED_CHAT_LIST_FAILED: {
      return {
        ...state,
        fetchingClosedChat: false,
        fetchingClosedChatError: true,
      };
    }
    // Update current chat
    case types.UPDATE_CURRENT_CHAT: {
      return {
        ...state,
        currentChat: action.chat,
      };
    }
    // Chat messages
    case types.FETCHING_CHAT_MESSAGES: {
      return {
        ...state,
        fetchingChatMessages: true,
        fetchingChatMessagesError: false,
      };
    }
    case types.FETCH_CHAT_MESSAGES_SUCCEED: {
      return {
        ...state,
        messages: action.messages,
        fetchingChatMessages: false,
      };
    }
    case types.FETCH_CHAT_MESSAGES_FAILED: {
      return {
        ...state,
        fetchingChatMessages: false,
        fetchingChatMessagesError: true,
      };
    }
    // Send message
    case types.SENDING_MESSAGE: {
      return {
        ...state,
        sendingMessage: true,
        sendingMessageError: false,
      };
    }
    case types.SEND_MESSAGE_SUCCEED: {
      let messages = [...state.messages];

      if (
        state.currentChat &&
        state.currentChat.createdAt &&
        action.payload.channel.url == state.currentChat.url
      ) {
        messages.push(action.payload.message);
      }

      return {
        ...state,
        messages: messages,
        sendingMessage: false,
      };
    }
    case types.SEND_MESSAGE_FAILED: {
      return {
        ...state,
        sendingMessage: false,
        sendingMessageError: true,
      };
    }
    // Add new message
    case types.ADD_NEW_MESSAGE: {
      let messages = [...state.messages];
      messages.push(action.message);
      return {
        ...state,
        messages: messages,
      };
    }
    // For replacing chat
    case types.REPLACING_CHAT_FROM_LIST: {
      let chats = [...state.openChats];
      let unreadCount = state.unreadMessageCount;
      let isAiviSessions = { ...state.isAiviSessions };
      let currentChat = state.currentChat;

      let index = chats.findIndex((chat) => {
        return chat.url === action.payload.channelUrl;
      });

      if (index != -1) {
        chats[index] = action.payload.channel;
        let isAivi = isAiviSession(chats[index]);
        isAiviSessions[chats[index].url] = isAivi;

        if (currentChat.url == chats[index].url) {
          currentChat = chats[index];
        }
      }

      if (action.payload.recountUnreadMessage) {
        unreadCount = 0;
        chats.forEach((chat) => {
          unreadCount += chat.unreadMessageCount;
        });
      }

      return {
        ...state,
        unreadMessageCount: unreadCount,
        isAiviSessions: isAiviSessions,
        openChats: chats,
        currentChat: currentChat,
      };
    }
    // Count unread message
    case types.COUNT_UNREAD_MESSAGE: {
      let msgCount = 0;
      state.openChats.forEach((channel) => {
        msgCount += channel.unreadMessageCount;
      });

      return {
        ...state,
        unreadMessageCount: msgCount,
      };
    }
    // Changing user password
    case types.CHANGING_USER_PASSWORD: {
      return {
        ...state,
        changingUserPassword: true,
      };
    }
    case types.CHANGING_USER_PASSWORD_SUCCEED: {
      return {
        ...state,
        changingUserPassword: false,
        user: action.user,
      };
    }
    case types.CHANGING_USER_PASSWORD_FAILED: {
      return {
        ...state,
        changingUserPassword: false,
        changingUserPasswordError: true,
      };
    }
    // Forgot password
    case types.FORGETTING_PASSWORD: {
      return {
        ...state,
        forgettingPassword: true,
      };
    }
    case types.FORGET_PASSWORD_SUCCEED: {
      return {
        ...state,
        forgettingPassword: false,
      };
    }
    case types.FORGET_PASSWORD_FAILED: {
      return {
        ...state,
        forgettingPassword: false,
        forgettingPasswordError: true,
      };
    }
    // Password reset
    case types.RESETTING_PASSWORD: {
      return {
        ...state,
        resettingPassword: true,
      };
    }
    case types.RESET_PASSWORD_SUCCEED: {
      return {
        ...state,
        resettingPassword: false,
      };
    }
    case types.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        resettingPassword: false,
        resettingPasswordError: true,
      };
    }
    // For contact us
    case types.SUBMITTING_CONTACT_US: {
      return {
        ...state,
        submittingContactUs: true,
      };
    }
    case types.SUBMIT_CONTACT_US_SUCCEED: {
      return {
        ...state,
        submittingContactUs: false,
      };
    }
    case types.SUBMIT_CONTACT_US_FAILED: {
      return {
        ...state,
        submittingContactUs: false,
        submittingContactUsError: true,
      };
    }
    case types.DELETED_USER: {
      return {
        ...state,
        user: {},
      };
    }
    case types.UPDATE_LIGHTCAST_SKILLS_LIST: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          lightCastSkills: action.payload.suggestSkills,
          userSkillInput: action.payload.userInput,
        },
      };
    }

    case types.UPDATE_LIGHTCAST_CERTIFICATES_LIST: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          userSkillInput: action.payload.userInput,
          lightCastCertificates: action.payload.suggestSkills,
        },
      };
    }

    case types.RESET_LIGHTCAST_SKILLS_LIST: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          lightCastSkills: ["Key in your skills"],
          lightCastCertificates: ["Key in your certificate & training"],
        },
      };
    }

    case types.UPDATE_USER_SKILLS_LIST: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          skills: [
            ...state.profileFields.skills,
            { name: action.payload.skill },
          ],
        },
      };
    }
    case types.REMOVE_USER_SKILL_FROM_LIST: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          skills: [...state.profileFields.skills].filter(
            (skill) => skill.name != action.payload.skill
          ),
        },
      };
    }
    case types.SAVING_USER_SKILLS: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          savingSkills: true,
        },
      };
    }
    case types.UPDATE_USER_SKILL_FAILED: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          savingSkills: false,
        },
      };
    }
    case types.SAVE_USER_SKILLS_STATUS: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          savingSkills: action.payload.stillSaving,
          skills: action.payload.skills,
        },
      };
    }
    case types.UPDATING_USER_EDUCATION: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          updatingEducations: action.payload.isUpdating,
        },
      };
    }
    case types.UPDATE_EDUCATION_FAILED: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          updatingEducations: false,
        },
      };
    }
    case types.UPDATE_USER_EDUCATION_LIST: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          educations: action.payload.newEducationList,
        },
      };
    }
    case types.UPDATE_EDUCATION_FORM_STATE: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          educationForm: action.payload.educationForm,
        },
      };
    }
    case types.RESET_EDUCATION_FORM: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          educationForm: {
            educationId: "",
            educationLevel: "",
            educationalInstitution: "",
            fieldOfStudy: "",
            graduationYear: "",
            description: "",
            destroyEducation: false,
          },
        },
      };
    }
    case types.FETCH_NEW_USER_INFO_SUCCEED: {
      return {
        ...state,
        user: action.payload.user,
        profileFields: {
          workingExps: action.payload.workingExp,
          honorAndAwards: action.payload.honorAndAward,
          extracurricular: action.payload.extracurricular,
          projects: action.payload.projects,
          certAndTrainings: action.payload.certAndTraining,
          educations: action.payload.educations,
          skills: action.payload.skills,
          languages: action.payload.language,
          loadState: true,
        },
        fetchingUserInfo: false,
        fetchingUserInfoError: false,
      };
    }
    case types.NEW_SESSION_LOGIN_SUCCEED: {
      return {
        ...state,
        user: action.payload.user,
        profileFields: {
          workingExps: action.payload.workingExp,
          honorAndAwards: action.payload.honorAndAward,
          extracurricular: action.payload.extracurricular,
          projects: action.payload.projects,
          certAndTrainings: action.payload.certAndTraining,
          educations: action.payload.educations,
          skills: action.payload.skills,
          languages: action.payload.language,
        },
        loggingIn: false,
        error: false,
      };
    }
    case types.SAVING_USER_LANGUAGES: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          savingUserLanguages: true,
        },
      };
    }
    case types.SAVE_USER_UPDATED_LANGUAGES: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          languages: action.payload.languages,
          savingUserLanguages: false,
        },
      };
    }
    case types.SAVING_USER_LANGUAGES_FAIL: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          savingUserLanguages: false,
        },
      };
    }
    case types.SAVING_USER_PROJECTS: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          savingUserProjects: true,
        },
      };
    }
    case types.UPDATE_PROJECTS_FAILED: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          savingUserProjects: false,
        },
      };
    }
    case types.SAVE_USER_UPDATED_PROJECTS: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          projects: action.payload.projects,
          savingUserProjects: false,
        },
      };
    }
    case types.UPDATE_PROJECT_FORM_STATE: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          projectForm: action.payload.projectForm,
        },
      };
    }
    case types.RESET_PROJECT_FORM_STATE: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          projectForm: {
            projectId: "",
            projectTitle: "",
            projectYear: "",
            projectUrl: "",
            description: "",
            destroyProject: false,
          },
        },
      };
    }
    default: {
      return state;
    }

    // For certificates section
    case types.UPDATE_CERTIFICATES: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          certAndTrainings: action.payload.newCertificate,
        },
      };
    }

    case types.UPDATE_CERTIFICATES_FORMS: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          certificatesForms: action.payload.certificatesForms,
        },
      };
    }

    case types.UPDATE_CERTIFICATES_SUCCEED: {
      return {
        ...state,
        submitCertificateError: action.payload.createStatus,
      };
    }

    case types.UPDATE_CERTIFICATES_FAILED: {
      return {
        ...state,
        submitCertificateError: action.payload.createStatus,
      };
    }

    case types.DELETE_CERTIFICATES: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          certAndTrainings: action.payload.newCertificate,
        },
      };
    }

    // For honours section
    case types.UPDATE_HONOURS: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          honorAndAwards: action.payload.newHonour,
        },
      };
    }

    case types.UPDATE_HONOURS_SUCCEED: {
      return {
        ...state,
        submitHonourError: action.payload.createStatus,
      };
    }

    case types.UPDATE_HONOURS_FAILED: {
      return {
        ...state,
        submitHonourError: action.payload.createStatus,
      };
    }

    case types.DELETE_HONOURS: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          honorAndAwards: action.payload.newHonour,
        },
      };
    }

    // For extracurricular section
    case types.UPDATE_EXTRACURRICULAR: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          extracurricular: action.payload.newExtracurricular,
        },
      };
    }

    case types.UPDATE_EXTRACURRICULAR_SUCCEED: {
      return {
        ...state,
        submitExtracurricularError: action.payload.createStatus,
      };
    }

    case types.UPDATE_EXTRACURRICULAR_FAILED: {
      return {
        ...state,
        submitExtracurricularError: action.payload.createStatus,
      };
    }

    case types.DELETE_EXTRACURRICULAR: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          extracurricular: action.payload.newExtracurricular,
        },
      };
    }

    case types.OPEN_SPECIALISATION_DROPDOWN: {
      return {
        ...state,
        openSpecialisationDropdown: action.payload,
      };
    }

    case types.OPEN_SUB_SPECIALISATION_DROPDOWN: {
      return {
        ...state,
        openSubSpecialisationDropdown: action.payload,
      };
    }

    case types.UPDATE_SUB_SPECIALISATION: {
      return {
        ...state,
        subSpecialisation: action.payload,
      };
    }

    // For work section
    case types.UPDATE_WORK: {
      return {
        ...state,
        profileFields: {
          ...state.profileFields,
          workingExps: action.payload.newWork,
        },
      };
    }

    case types.UPDATE_WORK_SUCCEED: {
      return {
        ...state,
        submitWorkError: action.payload.createStatus,
      };
    }

    case types.UPDATE_WORK_FAILED: {
      return {
        ...state,
        submitWorkError: action.payload.createStatus,
      };
    }

    case types.UPDATE_USER_BASIC_INFO: {
      return {
        ...state,
        basicInfo: action.payload.newInfo,
      };
    }

    case types.UPDATE_REPLACE_RESUME_POPUP_STATUS: {
      return {
        ...state,
        isReplaceResumeOpen: action.status,
      };
    }

    case types.UPDATE_RESUME_PROGRESS_POPUP_STATUS: {
      return {
        ...state,
        isResumeProgressOpen: action.status,
      };
    }

    case types.UPDATE_RESUME_BUILDING_STATUS: {
      return {
        ...state,
        isBuildingProgress: action.status,
      };
    }

    case types.UPDATE_RESUME_UPLOADING_STATUS: {
      return {
        ...state,
        isUploadingProgress: action.status,
      };
    }

    case types.UPDATE_CREATE_RESUME_POPUP_STATUS: {
      return {
        ...state,
        isCreateResumeOpen: action.status,
      };
    }

    case types.UPDATE_REJECTED_RESUME_POPUP_STATUS: {
      return {
        ...state,
        isRejectedResumeOpen: action.status,
      };
    }

    case types.UPDATE_IS_HIREDLY_RESUME_REJECTED_STATUS: {
      return {
        ...state,
        isHiredlyResumeRejected: action.status,
      };
    }

    case types.UPDATE_IN_REVIEW_RESUME_POPUP_STATUS: {
      return {
        ...state,
        isInReviewResumeOpen: action.status,
      };
    }

    case types.UPDATE_PREVIEW_RESUME_POPUP_STATUS: {
      return {
        ...state,
        isPreviewResumeOpen: action.status,
        isPreviewResume: action.from === "preview" ? true : false,
      };
    }

    case types.UPDATE_FAILED_UPLOAD_RESUME_POPUP_STATUS: {
      return {
        ...state,
        isFailedUploadResumeOpen: action.status,
      };
    }

    case types.UPDATE_LINKEDIN_RESUME_POPUP_STATUS: {
      return {
        ...state,
        isLinkedinResumeOpen: action.status,
      };
    }

    // For validating hiredly resume
    case types.VALIDATE_HIREDLY_RESUME: {
      return {
        ...state,
        validatingHiredlyResume: true,
      };
    }

    case types.VALIDATE_HIREDLY_RESUME_SUCCEED: {
      return {
        ...state,
        validatingHiredlyResume: false,
      };
    }

    case types.VALIDATE_HIREDLY_RESUME_FAILED: {
      return {
        ...state,
        validatingHiredlyResume: false,
      };
    }

    // For tooltip
    case types.SET_CURRENT_TOOLTIP: {
      return {
        ...state,
        currentTooltip: action.payload.currentTooltip,
        tooltipOpen: action.payload.tooltipOpen,
      };
    }

    case types.RESET_CURRENT_TOOLTIP: {
      return {
        ...state,
        currentTooltip: "",
        tooltipOpen: null,
      };
    }
    case types.REMOVE_PROFILE_PHOTO_FAILED: {
      return {
        ...state,
        user: action.user,
        isRemovingProfilePhoto: false,
      };
    }
    case types.REMOVE_PROFILE_PHOTO_SUCCESS: {
      return {
        ...state,
        user: action.user,
        isRemovingProfilePhoto: false,
      };
    }
    case types.REMOVE_PROFILE_PHOTO_IN_PROGRESS: {
      return {
        ...state,
        isRemovingProfilePhoto: true,
      };
    }
    case types.UPDATE_CURRENT_ONBOARDING_PAGE: {
      return {
        ...state,
        currentOnboardingPage: action.page,
      };
    }

    case types.UPDATE_JOB_TITLE_DROPDOWN_STATUS: {
      return {
        ...state,
        jobTitleDropdownStatus: action.status,
      };
    }

    case types.UPDATE_INDUSTRY_DROPDOWN_STATUS: {
      return {
        ...state,
        industryDropdownStatus: action.status,
      };
    }

    case types.UPDATE_SPECIALISATION_DROPDOWN_STATUS: {
      return {
        ...state,
        specialisationDropdownStatus: action.status,
      };
    }

    case types.UPDATE_MAJOR_DROPDOWN_STATUS: {
      return {
        ...state,
        majorDropdownStatus: action.status,
      };
    }

    case types.UPDATE_SKILL_DROPDOWN_STATUS: {
      return {
        ...state,
        skillDropdownStatus: action.status,
      };
    }

    case types.UPDATE_PROGRESS_BAR_VALUE: {
      return {
        ...state,
        progressbarValue: action.value,
      };
    }

    case types.UPDATE_SELECTED_RECOMMENDED_JOBS: {
      return {
        ...state,
        selectedRecommendedJobs: action.ids,
      };
    }

    case types.UPDATE_BULK_JOBS_POPUP_STATUS: {
      return {
        ...state,
        isBulkJobsPopupOpen: action.status,
      };
    }

    case types.UPDATE_SLIDER_DOTS_HISTORY: {
      return {
        ...state,
        sliderDotsHistory: action.history,
      };
    }

    case types.UPDATE_LANGUAGE_CHECK_POPUP_STATUS: {
      return {
        ...state,
        isLanguageCheckFail: action.status,
      };
    }

    case types.UPLOAD_RESUME_SUCCESS: {
      return {
        ...state,
        resume: action.payload,
      };
    }

    case types.HYDRATE_REDUX_USER: {
      return {
        ...state,
        user: action.user,
      };
    }

    case types.FETCH_PDPA_STATUS_SUCCESS: {
      return {
        ...state,
        pdpaAccepted: action.payload,
      };
    }
    case types.UPDATE_PDPA_STATUS_SUCCEED: {
      return {
        ...state,
        pdpaAccepted: action.payload,
      };
    }
    case types.UPDATE_PDPA_STATUS_FAILED: {
      return {
        ...state,
      };
    }
  }
};
