import * as types from "../types/navbar_type";

const initialState = {
  showSearchBar: false,
  showFilter: false,
  scrollYWhenSearchBarClicked: 0,
  jobListScrollY: 0,
  // For now, show log in modal control is in this reducer
  showSignInModal: false,
  isSignUp: false,
  showMobileFilter: false,
  signInText: "Log In",
  signUpText: "Create Account",
  socialLoginWarmedUp: false,
  showMobileSearch: false,
  fromNavbarSearch: false,
  navbarSearchKeyword: "",
  showStickyApply: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_NAVBAR_SEARCHBAR_STATUS: {
      return {
        ...state,
        showSearchBar: action.status,
      };
    }
    case types.UPDATE_NAVBAR_FILTER_STATUS: {
      return {
        ...state,
        showFilter: action.status,
      };
    }
    case types.UPDATE_SEARCH_BAR_CLICKED_SCROLL_Y: {
      return {
        ...state,
        scrollYWhenSearchBarClicked: action.scrollY,
      };
    }
    //For signin modal
    case types.UPDATE_SHOW_SIGNIN_MODAL_STATUS: {
      return {
        ...state,
        showSignInModal: action.status,
      };
    }
    case types.UPDATE_SIGNIN_MODAL_SIGNUP_STATUS: {
      return {
        ...state,
        isSignUp: action.status,
      };
    }
    //For Text
    case types.UPDATE_SIGNIN_TEXT: {
      return {
        ...state,
        signInText: action.text,
      };
    }
    case types.UPDATE_SIGNUP_TEXT: {
      return {
        ...state,
        signUpText: action.text,
      };
    }
    //For mobile filter
    case types.UPDATE_SHOW_MOBILE_FILTER_STATUS: {
      return {
        ...state,
        showMobileFilter: action.status,
      };
    }
    // For mobile search
    case types.UPDATE_SHOW_MOBILE_SEARCH: {
      return {
        ...state,
        showMobileSearch: action.status,
      };
    }
    // For navbar search keyword
    case types.UPDATE_NAVBAR_SEARCH_KEYWORD: {
      return {
        ...state,
        navbarSearchKeyword: action.keyword,
      };
    }

    case types.UPDATE_SHOW_STICKY_APPLY_BUTTON: {
      return {
        ...state,
        showStickyApply: action.toggle,
      };
    }

    default: {
      return state;
    }
  }
};
